.alert {
    @apply px-4 py-4 text-body-small inline-flex gap-2 items-start rounded-lg font-normal;
}

.alert .icon {
    @apply text-current;
    @apply w-7 h-7;
}

.alert .close-button .icon {
    @apply text-gray-800;
}

.alert.compact {
    @apply py-3 px-3 items-center gap-2.5;
    .icon {
        @apply w-6 h-6;
    }
}
